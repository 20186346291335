import { GridEnvironment } from './models';

export const environment: GridEnvironment = {
  environment: 'latest',
  csrfToken: 'csrftoken_nonprod',
  production: true,
  test: false,
  appConfig: {
    minIndexEdition: '2017-Q1',
    agGrid: {
      useUnsortIcon: true,
      suppressMenuHide: true
    },
    locationTables: {
      filterMaxOptions: 500
    },
  },
  paths: {
    img: '/assets/img',
    icons: '/assets/img/icons',
  },
  googleTagManagerId: 'GTM-WKT7W45',
  changePasswordUrl: null,
  blankMapTileUrl: 'https://tiles.latest.maplecroft.com/map-resources/tiles/rasters-2012.blank_raster/{z}/{x}/{y}.png',
  mapsBackendBaseURL: 'https://maps.latest.maplecroft.com/maps', // Assumes CloudFront.
  mapsOpenStreetMapVectorTilesetName: 'grid-analyse_v0.2.15',
  industry: {
    api: '/industry-risk/api/v1/',
    frontendApi: '/industry-risk/fe-api/v1/',
  },
};

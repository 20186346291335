import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
  FeatureToggleService,
  GlobalLegendModule,
  HttpErrorInterceptor,
  HttpHeadersInterceptor,
  IconModule,
  SearchableDropdownModule,
  ToastModule,
} from '@grid-ui/common';
import { ENVIRONMENT_CONFIG, environment } from '@grid-ui/environment';
import { GlobalAnnouncementsModule } from '@grid-ui/global-announcements';
import { GlobalRoutingModule } from '@grid-ui/global-routing';
import { LoggingService, SentryErrorHandler } from '@grid-ui/logging';
import { MapsService } from '@grid-ui/maps';
import {
  IGtmConfigProvider,
  UserAnalyticsModule,
} from '@grid-ui/user-analytics';
import { WhoAmIEffects } from '@grid-ui/whoami';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HorizontalButtonTabsModule } from '../app/shared-ui-components/horizontal-tabs/horizontal-tabs.module';
import { GtmConfigProviderService } from './analytics';
import { AppConfigModule, appInitializerFactory } from './app-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule, coreReducers } from './core';
import { ExtractManagementModule, PollingModule } from './extract-management';
import { GridComponent } from './grid/grid.component';
import { HomeModule } from './home';
import { PrivacySettingsModule } from './privacy-settings';
import { PageNotFoundModule } from './shared-ui-components/page-not-found';

const effectsForRoot = [WhoAmIEffects];

@NgModule({
  declarations: [AppComponent, GridComponent],
  imports: [
    BrowserModule,
    environment.test ? NoopAnimationsModule : BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: environment.csrfToken,
      headerName: 'X-CSRFToken',
    }),
    RouterModule,
    StoreModule.forRoot(coreReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(effectsForRoot),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    AppConfigModule,
    CoreModule,
    HomeModule,
    PageNotFoundModule,
    GlobalAnnouncementsModule,
    GlobalRoutingModule,
    GlobalLegendModule,
    PollingModule,
    PrivacySettingsModule,
    AppRoutingModule,
    ExtractManagementModule,
    UserAnalyticsModule,
    ToastModule,
    SearchableDropdownModule,
    IconModule,
    HorizontalButtonTabsModule,
  ],
  providers: [
    { provide: ENVIRONMENT_CONFIG, useValue: environment },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [LoggingService, MapsService, FeatureToggleService, Store],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeadersInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (platformLocation: PlatformLocation) =>
        platformLocation.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    { provide: IGtmConfigProvider, useClass: GtmConfigProviderService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<div *ngIf="indexNarrative.content !== null && indexNarrative.loadingStatus === RequestStatus.loaded"
  class="mc-index-info-popover info-popover">
  <button type="button" class="mc-scroll-to-top btn btn-compact mc-btn-primary" *ngIf="showScrollToTop" (click)="scrollToTopClicked()">
    <i class="fa fa-chevron-up"></i> Back to top
  </button>
  <div class="modal-container">
    <div #popoverContainer (scroll)="onScroll($event)" class="modal-body mc-index-info-popover-content-container">
      <div class="modal-body-header">
        <grid-ui-icon
          type="button"
          id="dismissButton"
          class="close-icon"
          aria-label="Dismiss"
          (click)="activeModal.dismiss('Cross click')"
          data-hook="ask-an-analyst-dismiss-btn"
          xauto-index-info-modal-close
          ngbTooltip="Close window"
          name="cross"
        ></grid-ui-icon>
      </div>

      <div class="mc-index-info-popover-header d-flex justify-content-between">
        <input ngbAutofocus class="no-size">
        <h2 class="mc-index-name">{{ indexNarrative.content!.name }}</h2>
      </div>

      <ng-container
        *ngIf="indexNarrative!.content!.content && indexNarrative!.content!.content.sections; else noContentTpl">
        <div class="mc-index-info-popover-links-bar">
          <div class="mc-flex-wrapper">

            <div class="mc-link mc-pdf-link mr-auto d-flex align-items-center" *ngIf="!downloading"
              (click)="onDownloadPdfUrl()">
              <ng-container *ngIf="!downloadFailed; else showErrorMsg;">
                <div class="mc-pdf-image-container">
                  <img class="mc-pdf-image" src="assets/img/icons/pdf-white.svg">
                </div>
                <span>PDF</span>
              </ng-container>

              <ng-template #showErrorMsg>
                <svg class="mc-pdf-image">
                  <use xlink:href="#shape-pdf-stroke" />
                </svg>
                <strong data-hook="mc-info-pdf-retry-text">Download failed, please retry</strong>
              </ng-template>
            </div>

            <div class="mr-auto d-flex align-items-center" *ngIf="downloading">
              <div class="mc-pdf-image-container">
                <span class="processing-pdf" data-hook="mc-report-dropdown-processing-pdf">
                  <svg viewBox="0 0 512 512">
                    <path fill="black"
                      d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z">
                    </path>
                  </svg>
                </span>
                <span *ngIf="downloading" class="pdf-text fadeInMove">Processing PDF</span>
              </div>
            </div>

            <a class="mc-link mc-anchor-link"
              *ngFor="let section of indexNarrative.content!.content.sections; let sectionIndex = index"
              (click)="scrollToBookmark(sectionIndex)">
              <grid-ui-icon class="icon-next" name="arrow-curve-right"></grid-ui-icon>
              <span>{{section.title}}</span>
            </a>
          </div>
        </div>
        <div #sectionsContainer (scroll)="onScroll($event)" class="mc-index-info-popover-content">
          <div class="mc-section" *ngFor="let section of indexNarrative.content!.content.sections; let lastSection = last"
            [class.sources-section]="section.title === 'Sources'">
            <h3 class="mc-section-title">{{section.title}}</h3>
            <div class="grid-ui-body-item-container">
              <grid-ui-body-item *ngFor="let bodyItem of section.body; let index = index;" [bodyItem]="bodyItem"
                [previousBodyItem]="index > 0 ? section.body[index - 1] : undefined" [enableNestedBodyItem]="true"
                (writtenContentLinkActionEmit)="handleWrittenContentLinkAction($event)">
              </grid-ui-body-item>
            </div>
            <hr *ngIf="!lastSection" class="mc-section-divider">
          </div>
        </div>
      </ng-container>

      <ng-template #noContentTpl>
        <p class="mc-index-info-popover-no-content">No content is available</p>
      </ng-template>
    </div>
  </div>
</div>

<div class="mc-index-info-popover loading-container" *ngIf="indexNarrative.loadingStatus === RequestStatus.loading">
  <div class="mc-index-info-popover-content-container">
    <div class="mc-index-info-popover-content-loader">
      <grid-ui-loading [message]="'Loading, please wait..'"></grid-ui-loading>
    </div>
  </div>
</div>
<div class="mc-index-info-popover error-container"
  *ngIf="indexNarrative.loadingStatus === RequestStatus.error && indexNarrative.error !== null">
  <div class="mc-index-info-popover-content-container">
    <div class="mc-index-info-popover-header mc-index-info-popover-margin">
      <button type="button" id="dismissButton" class="mc-dismiss-icon close" aria-label="Dismiss"
        xauto-index-info-modal-close (click)="activeModal.dismiss('Cross click')">
        <svg class="icon small">
          <use xlink:href="#shape-close" />
        </svg>
      </button>
    </div>
    <grid-ui-error [errorCode]="indexNarrative.error!.errorCode" [errorMessage]="indexNarrative.error!.errorMessage"
      [errorPrimaryAction]="indexNarrative.error!.errorPrimaryAction" [showContact]="indexNarrative.error!.showContact"
      [contactEmail]="indexNarrative.error!.contactEmail" (primaryActionClick)="retryDataLoad()">
    </grid-ui-error>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { environment } from '@grid-ui/environment';
import {
    ApiLocalCacheService,
    CountryRiskIndicesService,
    CountryServiceV4,
    DataAlertService,
    PaginationService,
    PortalHttpClient,
    QueryParametersService,
    RetryAttemptsMapperService,
} from './api';
import { CustomSelectableHeaderModule } from './shared-components/custom-selectable-header/custom-selectable-header.module';
import {
    FrontendAssertsService,
    IndustryMetadataService,
    SavedSearchesService,
    SubscriptionService,
} from './shared-services';
import { DownloadResourceService } from './shared-utilities';

@NgModule({
  imports: [
    CommonModule,
    CustomSelectableHeaderModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: environment.csrfToken,
      headerName: 'X-CSRFToken'
    }),
  ],
  providers: [
    ApiLocalCacheService,
    CountryRiskIndicesService,
    IndustryMetadataService,
    SubscriptionService,
    QueryParametersService,
    PortalHttpClient,
    RetryAttemptsMapperService,
    PaginationService,
    DownloadResourceService,
    FrontendAssertsService,
    CountryServiceV4,
    SavedSearchesService,
    DataAlertService,
  ],
})
export class GridCommonModule { }

import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@grid-ui/environment';

/**
 * A pipe to add correct image path from a given
 */
@Pipe({
  name: 'mcImageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  public transform(value: string): string {
    const imagePath = environment.paths.img;
    return `${imagePath}/${value}`;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'grid-ui-body-item-lop-table',
  templateUrl: './body-item-lop-table.component.html',
  styleUrls: ['./body-item-lop-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyItemLopTableComponent {
}

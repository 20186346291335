import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserPermissions } from '@grid-ui/api-models';
import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  GeoLocation,
  HttpGETCustomOptions,
  HttpOptions,
  NonPaginatedResourceConfig,
  PaginatedResourceConfig,
  PaginationService,
  PortalHttpClient,
  QueryParams
} from '@grid-ui/common';
import { WhoAmIService } from '@grid-ui/whoami';
import { isEmpty } from 'ramda';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  AllGeoLocationCollectionQueryParams,
  CountryGeoLocationCollectionQueryParams,
  GeoLocationCollection,
  GeoLocationCollectionQueryParams,
  LocationFilterQueryParams,
  SiteGeoLocationCollectionQueryParams,
} from '../../../shared-models';
import { PortalHttpClientRequestParametersWithBody } from '../../models';
import {
  ApiCreateLocation,
  ApiGeoLocation,
  ApiGeoLocationCollection,
  DownloadRequestResponse,
  EditedSiteInformation,
  ListRequestDeleteSiteResponse,
  RequestDeleteSiteResponse,
  RequestDeleteSiteStatus,
  SiteDeleteResponse,
  V4LocationCollection,
  V4LocationFlattenedCollection,
  V4LocationQueryParamsWithFields,
  V4LocationResponse,
} from '../models';
import { mapLocations } from '../utils';

export const V4_LOCATION_ATTRIBUTES_MAP = {
  'geo_id':'location_id',
  'name':'location_name',
  // no mapping but these are concrete fields
  'created':'created',
  'region':'region',
  'type':'type',
  'latitude': 'latitude',
  'longitude':'longitude',
  'customer_id':'customer_id',
  'city':'city',
  'postcode':'postcode',
  'country_name':'country_name',
  'country_code':'country_code'
} as Record<string, string>;

export const V4_ATTRIBUTES_FILTER_MAP = {
  'contains': '__icontains',
  'startsWith': '__istartswith',
  'endsWith': '__iendswith',
  'equals': '__iexact',
  'greaterThan': '__gt',
  'lessThan': '__lt'
} as Record<string, string>;

export const V4_NUMBER_ATTRIBUTES_FILTER_MAP = {
  'equals': '__exact',
  'greaterThan': '__gt',
  'lessThan': '__lt'
} as Record<string, string>;

@Injectable({
  providedIn: 'root'
})
/**
 * Service for accessing the Locations API
 */
export class LocationsService {
  V4_LOCATION_ATTRIBUTES_MAP = V4_LOCATION_ATTRIBUTES_MAP;
  V4_LOCATION_ATTRIBUTES_REVERSED_MAP: Record<string, string> = Object
    .entries(V4_LOCATION_ATTRIBUTES_MAP)
    .reduce((a, [k, v]) => ({ ...a, [v]: k }), {});

  private locationsResourceConfig: PaginatedResourceConfig;
  private locationResourceConfig: NonPaginatedResourceConfig;
  private singleLocationResourceConfig: NonPaginatedResourceConfig;
  private bulkDeleteResourceConfig: NonPaginatedResourceConfig;
  private V4deleteRequestResourceConfig: NonPaginatedResourceConfig;
  private V4deleteRequestResourceDetailConfig: NonPaginatedResourceConfig;
  private V4LocationResourceConfig: PaginatedResourceConfig;
  private V4createDownloadRequestResourceConfig: NonPaginatedResourceConfig;
  private V4getDownloadRequestResourceConfig: NonPaginatedResourceConfig;
  private V4downloadRequestResourceConfig: NonPaginatedResourceConfig;
  private feLocationResourceConfig: PaginatedResourceConfig;


  private LOCATIONS_INCLUDED_ATTRIBUTES = [
    'geo_id',
    'type',
    'collection',
    'name',
    'region',
    'customer_id',
    'country_code',
    'country_name',
    'latitude',
    'longitude',
  ];

  constructor(
    private readonly http: PortalHttpClient,
    private readonly paginationService: PaginationService,
    private readonly whoAmIService: WhoAmIService,
  ) {
    this.locationsResourceConfig = API_SERVICES_CONFIG.v3.locations._configuration;
    this.locationResourceConfig = API_SERVICES_CONFIG.v3.locations.location._configuration;
    this.singleLocationResourceConfig = API_SERVICES_CONFIG.v3.locations.location._configuration;
    this.bulkDeleteResourceConfig = API_SERVICES_CONFIG.v3.locations.bulkDelete._configuration;
    this.V4deleteRequestResourceConfig = API_SERVICES_CONFIG.v4.location.deleteRequest._configuration;
    this.V4deleteRequestResourceDetailConfig = API_SERVICES_CONFIG.v4.location.deleteRequest.detail._configuration;
    this.V4LocationResourceConfig = API_SERVICES_CONFIG.v4.location._configuration;
    this.V4createDownloadRequestResourceConfig = API_SERVICES_CONFIG.v4.location.downloadRequest._configuration;
    this.V4getDownloadRequestResourceConfig = API_SERVICES_CONFIG.v4.location.downloadRequest.detail._configuration;
    this.V4downloadRequestResourceConfig = API_SERVICES_CONFIG.v4.location.downloadRequest.download._configuration;
    this.feLocationResourceConfig = API_SERVICES_CONFIG.feApi.locations.events._configuration;
  }

  /**
   * Add a location to the available pool of GRiD locations on the user account.
   * When bulkValidation is true, the backend will use the same validation logic as
   * when bulk-uploading locations with a spreadsheet.
   * @param location Location to be added.
   * @param bulkValidation Whether to use bulkValidation rules or not.
   */
  public addLocation(
    location: ApiCreateLocation,
    bulkValidation: boolean
  ): Observable<GeoLocation> {
    let params: HttpParams | undefined;
    if (bulkValidation) {
      params = new HttpParams({
        fromObject: {
          bulk_validation: 'true'
        }
      });
    }
    return this.http.post<ApiGeoLocation>(
      this.locationsResourceConfig,
      {
        body: location,
        httpOptions: { params },
        retryOptions: {
          retryWhenCb:
            errorResponse => errorResponse.status !== undefined && errorResponse.status !== 400
        }
      }
    ).pipe(
      map(apiLocation => mapLocations(apiLocation))
    );
  }

  // TODO: Compare to `addLocation(...)` and consolidate as appropriate
  public addSite(
    newSiteData: ApiCreateLocation,
    useBulkValidationRules?: boolean
  ): Observable<GeoLocation> {
    const requestOptions: PortalHttpClientRequestParametersWithBody = {
      body: newSiteData,
      retryOptions: {
        customRetryAttempts: 0
      }
    };
    if (useBulkValidationRules) {
      const params: HttpParams = new HttpParams({ fromObject: { bulk_validation: 'true' } });
      requestOptions.httpOptions = { params };
    }
    return this.http.post<ApiGeoLocation>(
      this.locationsResourceConfig,
      requestOptions
    ).pipe(
      map(apiLocation => mapLocations(apiLocation))
    );
  }

  public updateSite(
    id: string,
    newSiteData: ApiCreateLocation,
    useBulkValidationRules?: boolean
  ): Observable<GeoLocation> {
    const requestOptions: PortalHttpClientRequestParametersWithBody = {
      body: newSiteData,
      pathParams: { id },
      retryOptions: {
        customRetryAttempts: 0
      }
    };
    if (useBulkValidationRules) {
      const params: HttpParams = new HttpParams({ fromObject: { bulk_validation: 'true' } });
      requestOptions.httpOptions = { params };
    }
    return this.http.put<ApiGeoLocation>(
      this.locationResourceConfig,
      requestOptions
    ).pipe(
      map(apiLocation => mapLocations(apiLocation))
    );
  }


  public bulkDeleteSitesByFilters(
    queryParams: SiteGeoLocationCollectionQueryParams | GeoLocationCollectionQueryParams = {}
  ): Observable<SiteDeleteResponse> {
    const options: HttpOptions = <HttpOptions>{ params: <QueryParams>{ ...queryParams, delete_all: '*' } };

    return this.http.delete<SiteDeleteResponse>(
      this.bulkDeleteResourceConfig,
      {
        httpOptions: options
      }
    );
  }

  public deleteMultipleSitesByGeoIds(geoIds: string[]): Observable<SiteDeleteResponse> {
    const concatenatedGeoIds: string = geoIds.join(',');
    const params: QueryParams = {
      geo_id: concatenatedGeoIds
    };

    return this.http.delete<SiteDeleteResponse>(
      this.bulkDeleteResourceConfig,
      {
        httpOptions: { params }
      }
    );
  }

  public listDeleteRequestSites(
    statuses: `${RequestDeleteSiteStatus}`[],
    pageSize?: number
  ): Observable<ListRequestDeleteSiteResponse> {
    const concatenatedStatuses = statuses.join(',');
    const queryParams: QueryParams = <QueryParams>{
      statuses: concatenatedStatuses,
    };

    if (pageSize !== undefined) {
      queryParams.page_size = pageSize;
    }

    return this.http.get<ListRequestDeleteSiteResponse>(
      this.V4deleteRequestResourceConfig,
      {
        queryParams
      },
    );
  }

  public getDeleteRequestSites(id: string): Observable<RequestDeleteSiteResponse> {
    return this.http.get<RequestDeleteSiteResponse>(
      this.V4deleteRequestResourceDetailConfig,
      {
        pathParams: { id }
      }
    );
  }

  public createDeleteRequestSites(
    queryParams: LocationFilterQueryParams = {},
    geoIds: string[] = [],
    all = false,
    is_creator?: boolean,
  ): Observable<RequestDeleteSiteResponse> {
    const hasQueryParams = !!Object.keys(queryParams).length;
    const body: {
      location_filter: LocationFilterQueryParams;
    } = {
      location_filter: {},
    };

    if (all && hasQueryParams) {
      body.location_filter = { ...queryParams };
    } else if (all) {
      body.location_filter.all = all;
    } else if (geoIds.length) {
      body.location_filter.location_id__in = geoIds;
    }

    if (is_creator) {
      body.location_filter.is_creator = true;
    }

    return this.http.post<RequestDeleteSiteResponse>(
      this.V4deleteRequestResourceConfig,
      { body },
    );
  }

  public createDeleteRequestEntitledSites(
    queryParams: LocationFilterQueryParams = {},
    geoIds: string[] = [],
    all = false,
    entitled = true,
    is_creator?: boolean,
  ): Observable<RequestDeleteSiteResponse> {
    return this.whoAmIService.getPermissions().pipe(
      switchMap(permissions => this.createDeleteRequestSites(
        this.resolveDeleteReqEntitledQueryParams(
          queryParams,
          permissions,
          entitled,
        ),
        geoIds,
        all,
        is_creator,
      )),
    );
  }

  public deleteAllSites(unentitled: 0 | 1): Observable<SiteDeleteResponse> {
    const params: QueryParams = <QueryParams>{ delete_all: '*', unentitled };

    return this.http.delete<SiteDeleteResponse>(
      this.bulkDeleteResourceConfig,
      {
        httpOptions: { params }
      }
    );
  }

  public deleteSite(id: string): Observable<null> {
    return this.http.delete<null>(
      this.singleLocationResourceConfig, { pathParams: { id } }
    );
  }

  public getDownloadRequest(id: string): Observable<DownloadRequestResponse> {
    return this.http.get<DownloadRequestResponse>(
      this.V4getDownloadRequestResourceConfig,
      { pathParams: { id } },
    );
  }

  public createDownloadRequest(
    queryParams: LocationFilterQueryParams = {},
    fields: string[] = [],
    geoIds: string[] = [],
    all = false,
    is_creator?: boolean
  ): Observable<DownloadRequestResponse> {
    const body = {
      location_filter: {} as LocationFilterQueryParams,
      use_field_labels: true,
      fields,
    };

    if (is_creator) {
      body.location_filter.is_creator = true;
    }

    if (all && !isEmpty(queryParams)) {
      body.location_filter = { ...queryParams };
    } else if (all) {
      body.location_filter.all = all;
    } else if (geoIds.length) {
      body.location_filter.location_id__in = geoIds;
    }

    return this.http.post<DownloadRequestResponse>(
      this.V4createDownloadRequestResourceConfig,
      { body },
    );
  }

  public editSite(id: string, editSiteData: EditedSiteInformation): Observable<GeoLocation> {
    return this.http.put<ApiGeoLocation>(
      this.singleLocationResourceConfig,
      {
        body: editSiteData,
        pathParams: { id }
      }
    ).pipe(
      map(apiEditedSite => mapLocations(apiEditedSite))
    );
  }

  /**
   * Get a list of all locations (i.e. countries and sites) which a user is entitled to and meet the specified criteria.
   *
   * @param queryParams An optional object with query parameters. Omitting the query parameters object or passing in an empty
   * object {} will return all locations within the user entitlement
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getLocations(
    queryParams: AllGeoLocationCollectionQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<GeoLocationCollection> {
    return this.getParameterizedLocations(queryParams, options);
  }

  public getLocationsV4(
    queryParams: V4LocationQueryParamsWithFields = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<V4LocationCollection> {
    return this.getParameterizedLocationsV4(queryParams, options);
  }

  public getFELocations(
    queryParams: V4LocationQueryParamsWithFields = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<V4LocationCollection> {
    return this.getFEParameterizedLocations(queryParams, options);
  }

  public getLocationsURLPathSegment(): string {
    return this.locationsResourceConfig.path;
  }

  public getMaplecroftSiteLocations(
    queryParams: SiteGeoLocationCollectionQueryParams | GeoLocationCollectionQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<GeoLocationCollection> {
    (queryParams as SiteGeoLocationCollectionQueryParams).reference = '1';
    return this.getSiteLocations(queryParams, options);
  }

  public getMaplecroftSiteLocationsV4(
    queryParams: V4LocationQueryParamsWithFields = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    flattenAttributes = true,
  ): Observable<V4LocationCollection | V4LocationFlattenedCollection> {
    return this.getSiteLocationsV4({ ...queryParams, is_reference: true }, options).pipe(
      map(locationCollection => flattenAttributes
        ? this.flattenLocationsCollectionV4(locationCollection)
        : locationCollection
      ),
    );
  }

  public getMySiteLocations(
    queryParams: SiteGeoLocationCollectionQueryParams | GeoLocationCollectionQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<GeoLocationCollection> {
    (queryParams as SiteGeoLocationCollectionQueryParams).reference = '0';
    return this.getSiteLocations(queryParams, options);
  }

  public getSingleSiteByGeoId(id: string, options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS): Observable<GeoLocation> {
    return this.http.get<ApiGeoLocation>(
      this.singleLocationResourceConfig,
      {
        ...options,
        pathParams: { id },
      }
    ).pipe(
      map(apiSite => mapLocations(apiSite))
    );
  }

  public checkCustomerIdExistence(
    customer_id: string,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<boolean> {
    return this.http.getPaginated<ApiGeoLocationCollection>(
      this.locationsResourceConfig,
      {
        ...options,
        queryParams: {
          customer_id,
          reference: 0
        },
      }
    ).pipe(
      map(response => response.response.total > 0)
    );
  }

  /**
   * Get a list of all sites (i.e. site locations) which a user is entitled to and meet the specified criteria.
   *
   * @param queryParams An optional object with query parameters. Omitting the query parameters object or passing in an empty
   * object {} will return all site locations within the user entitlement
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getSiteLocations(
    queryParams: SiteGeoLocationCollectionQueryParams | GeoLocationCollectionQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<GeoLocationCollection> {
    const filteredQueryParams = this.getFilteredQueryParams(queryParams);
    return this.getParameterizedLocations({ ...filteredQueryParams, type: 'site' }, options);
  }

  public getSiteLocationsV4(
    queryParams: V4LocationQueryParamsWithFields,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    flattenAttributes = true,
  ): Observable<V4LocationCollection | V4LocationFlattenedCollection> {
    return this.getParameterizedLocationsV4(queryParams, options).pipe(
      map(locationCollection => flattenAttributes
        ? this.flattenLocationsCollectionV4(locationCollection)
        : locationCollection
      ),
    );
  }

  private flattenLocationsCollectionV4(locationCollection: V4LocationCollection): V4LocationFlattenedCollection {
    return {
      ...locationCollection,
      results: locationCollection.results.map(l => ({
        ...l,
        ...(l.attributes || {}),
      })),
    };
  }

  private getFilteredQueryParams<T>(queryParams: T & {fields?: string[] | undefined}, v4 = false): T & {fields?: string[] | undefined} {
    // remove fields that are included by default, if these are left in the request the values will all be set to null
    if (queryParams.fields && queryParams.fields.length > 0) {
      const concreteAttrs = v4
        ? this.LOCATIONS_INCLUDED_ATTRIBUTES.map(a => (this.V4_LOCATION_ATTRIBUTES_MAP as any)[a] || a)
        : this.LOCATIONS_INCLUDED_ATTRIBUTES;
      queryParams.fields = queryParams.fields.filter(x => !concreteAttrs.includes(x));
    }
    return queryParams;
  }

  /**
   * Helper method to get a list of sites meeting the specified criteria
   *
   * @private
   * @param queryParams An optional object with query parameters. Omitting the query parameters object or passing in an empty
   * object {} will return all locations within the user entitlement
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  private getParameterizedLocations(
    queryParams: AllGeoLocationCollectionQueryParams | CountryGeoLocationCollectionQueryParams | SiteGeoLocationCollectionQueryParams,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<GeoLocationCollection> {

    // TODO: Caching will be implemented using ETag, once P2-132 is addressed

    return this.http.getPaginated<ApiGeoLocationCollection>(
      this.locationsResourceConfig,
      {
        ...options,
        queryParams
      }
    )
      .pipe(
        map(responseContext => {
          const geoLocationCollection: GeoLocationCollection = {
            total: responseContext.response.total,
            results: responseContext.response.results.map(result => mapLocations(result)),
            paginationContext: this.paginationService.getNewPaginationContext(
              responseContext.response.links, responseContext.queryParams)
          };
          return geoLocationCollection;
        })
      );
  }

  private getParameterizedLocationsV4(
    queryParams: V4LocationQueryParamsWithFields,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<V4LocationCollection> {
    return this.http.getPaginated<V4LocationResponse>(
      this.V4LocationResourceConfig,
      {
        ...options,
        queryParams
      }
    )
      .pipe(
        map(responseContext => ({
          total: responseContext.response.total,
          results: responseContext.response.results,
          paginationContext: this.paginationService.getNewPaginationContext(
            responseContext.response.links,
            responseContext.queryParams,
          )
        } as V4LocationCollection))
      );
  }

  private getFEParameterizedLocations(
    queryParams: V4LocationQueryParamsWithFields,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<V4LocationCollection> {
    return this.http.getPaginated<V4LocationResponse>(
      this.feLocationResourceConfig,
      {
        ...options,
        queryParams
      }
    )
      .pipe(
        map(responseContext => ({
          total: responseContext.response.total,
          results: responseContext.response.results,
          paginationContext: this.paginationService.getNewPaginationContext(
            responseContext.response.links,
            responseContext.queryParams,
          )
        } as V4LocationCollection))
      );
  }

  private resolveDeleteReqEntitledQueryParams(
    queryParams: LocationFilterQueryParams,
    permissions: UserPermissions,
    entitled = true,
  ): LocationFilterQueryParams {
    const filterKey = entitled ? 'country_code__in' : 'country_code__not_in';
    const filterCodes = queryParams[filterKey]?.split(',') || [];
    const entitledCodes = permissions['country-risk']?.countries || [];
    const cleanCodes = Array.from(new Set([ ...filterCodes, ...entitledCodes ]));

    return { ...queryParams, [filterKey]: cleanCodes.join(',') };
  }
}

import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  CountryRiskView,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PaginatedCollection,
  PaginatedResourceConfig,
  PaginationService,
  PortalHttpClient,
  RiskView
} from '@grid-ui/common';
import { LoggingService } from '@grid-ui/logging';
import { Severity } from '@sentry/browser';
import { EMPTY, Observable } from 'rxjs';
import { expand, map, reduce } from 'rxjs/operators';
import { PaginatedSharedLinks, SharedLink } from '../../../shared-models';
import { ApiCountryRiskConfiguration, ApiCountryRiskConfigurationCollection } from '../../models';
import { mapRiskViewFromApiToApp } from '../../utils';
import {
  CountryRiskConfigurationsQueryParams,
  CountryRiskEditConfiguration,
  SharedLinksBulkDeletePayload,
  SharedLinksQueryParams,
} from '../models';


export type RiskViewPaginatedCollection = PaginatedCollection<CountryRiskView, CountryRiskConfigurationsQueryParams>;


@Injectable()
/**
 * Service for accessing the Country Risk Configurations (Views) API
 */
export class CountryRiskViewsService {

  private configurationsResourceConfig: PaginatedResourceConfig;
  private configurationResourceConfig: NonPaginatedResourceConfig;
  private createDraftResourceConfig: NonPaginatedResourceConfig;
  private saveDraftResourceConfig: NonPaginatedResourceConfig;
  private sharedLinksResourceConfig: PaginatedResourceConfig;
  private sharedLinksDetailResourceConfig: NonPaginatedResourceConfig;
  private sharedLinksBulkDeleteResourceConfig: NonPaginatedResourceConfig;
  private sharedLinksAcceptResourceConfig: NonPaginatedResourceConfig;


  constructor(
    private readonly http: PortalHttpClient,
    private readonly loggingService: LoggingService,
    private readonly paginationService: PaginationService,
  ) {
    this.configurationsResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurations._configuration;
    this.configurationResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration._configuration;
    this.createDraftResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurations.createDraft._configuration;
    this.saveDraftResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurations.save._configuration;
    this.sharedLinksResourceConfig = API_SERVICES_CONFIG.feApi.sharedLinks._configuration;
    this.sharedLinksDetailResourceConfig = API_SERVICES_CONFIG.feApi.sharedLinks.detail._configuration;
    this.sharedLinksBulkDeleteResourceConfig = API_SERVICES_CONFIG.feApi.sharedLinks.bulkDelete._configuration;
    this.sharedLinksAcceptResourceConfig = API_SERVICES_CONFIG.feApi.sharedLinks.detail.accept._configuration;
  }

  /**
   * Change the name of a risk view. Returns an Observable of the updated Risk View if the name change was successful.
   * Otherwise, handle HttpErrorResponse.
   *
   * If the view is a draft view, the local storage will be updated.
   *
   * @param id Unique ID of risk view
   * @param newName New name to use for the risk view.
   */
  public changeViewName(id: number, newName: string): Observable<CountryRiskView> {
    const patch: CountryRiskEditConfiguration = { name: newName };
    return this.saveEdits(id, patch, 0);
  }

  /**
   * Create a draft version of the given view
   * @param viewId The ID of the risk view for which to create a draft view
   */
  public createDraft(viewId: number): Observable<CountryRiskView> {
    return this.http.post<ApiCountryRiskConfiguration>(
      this.createDraftResourceConfig,
      {
        body: null,
        pathParams: { viewId }
      }
    ).pipe(
      map((data: ApiCountryRiskConfiguration) => mapRiskViewFromApiToApp(data))
    );
  }

  /**
   * Create new View
   */
  public createNewView(customRetryAttempts?: number): Observable<CountryRiskView> {
    /* Post Request object */
    const apiPostRequest = {
      'collection': 'standard',
      'shared': false
    };

    return this.http.post<ApiCountryRiskConfiguration>(
      this.configurationsResourceConfig,
      {
        body: apiPostRequest,
        retryOptions: { customRetryAttempts }
      }
    ).pipe(
      map((data: ApiCountryRiskConfiguration) => mapRiskViewFromApiToApp(data))
    );
  }

  /*
   * Delete a given view. Returns a boolean Observable with value "true" if deletion was successful.
   * Otherwise, handle HttpErrorResponse.
   *
   * IMPORTANT Use only for Risk Views created by the User.
   *
   * @param id View ID to be deleted, cannot delete multiple views must be only
   * one ID.
   */
  public deleteView(viewId: number, customRetryAttempts?: number): Observable<boolean> {
    return this.http.delete<Record<string, unknown>>(
      this.configurationResourceConfig,
      {
        pathParams: { id: viewId },
        retryOptions: { customRetryAttempts }
      }
    ).pipe(map(() => true));
  }

  /**
   * A convenience method to get a list of all country risk views (configurations) which where generated by or shared with the user,
   * excluding Verisk Maplecroft reference views.
   *
   * @param creator An optional string specifying the email address of the creator of the views to include in the results set. Omitting
   * the parameter or setting it to null, will not filter the results by creator.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getMyRiskViews(
    creator: string | null = null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    customQueryParams: CountryRiskConfigurationsQueryParams = {}
  ): Observable<CountryRiskView[]> {
    const queryParams: CountryRiskConfigurationsQueryParams = {
      ...customQueryParams,
      reference: false
    };
    if (creator !== null) {
      queryParams.creator = creator;
    }
    return this.getRiskViews(queryParams, options);
  }

  /**
   * Get an individual country risk view (configuration) for a given view ID.
   *
   * @param viewId Mandatory ID of the view being requested.
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRiskView(
    viewId: number,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CountryRiskView> {
    return this.http.get<ApiCountryRiskConfiguration>(
      this.configurationResourceConfig,
      {
        ...options,
        pathParams: { id: viewId }
      }
    ).pipe(
      map(configCollection => mapRiskViewFromApiToApp(configCollection))
    );
  }

  /**
   * Get a list of all country risk views (configurations) which a user is entitled to and meet the specified criteria.
   *
   * @param queryParams An optional object with query parameters. Omitting the query parameters object or passing in an empty
   * object {} will return all risk views within the user entitlement
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getRiskViews(
    queryParams: CountryRiskConfigurationsQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<CountryRiskView[]> {
    // TODO: Old "naive" caching logic removed, caching will be implemented using ETag, once P2-132 is addressed
    return this.http.get<ApiCountryRiskConfigurationCollection>(
      this.configurationsResourceConfig,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map(configCollection => configCollection.results.map(
        v => this.riskViewsErrorLoggingWrapper(v)
      ))
    );
  }

  public getPaginatedRiskViews(
    queryParams: CountryRiskConfigurationsQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<RiskViewPaginatedCollection> {
    return this.http.getPaginated<ApiCountryRiskConfigurationCollection>(
      this.configurationsResourceConfig,
      {
        ...options,
        queryParams
      }
    ).pipe(
      map(responseContext => ({
        total: responseContext.response.total,
        results: responseContext.response.results.map(v => this.riskViewsErrorLoggingWrapper(v)),
        paginationContext: this.paginationService.getNewPaginationContext(
          responseContext.response.links,
          responseContext.queryParams,
        )
      })),
    );
  }

  /**
   * A convenience method to get a list of Verisk Maplecroft reference country risk views (configurations) which the user is entitled to.
   *
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getVeriskMaplecroftRiskViews(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    customQueryParams: CountryRiskConfigurationsQueryParams = {}
  ): Observable<CountryRiskView[]> {

    return this.getRiskViews({ ...customQueryParams, reference: true }, options);
  }

  public getPaginatedVeriskMaplecroftRiskViews(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    customQueryParams: CountryRiskConfigurationsQueryParams = {}
  ): Observable<RiskViewPaginatedCollection> {

    return this.getPaginatedRiskViews({ ...customQueryParams, reference: true }, options);
  }

  public resolveExistingView(viewId: number): Observable<CountryRiskView> {

    return this.http.get<ApiCountryRiskConfiguration>(
      this.configurationResourceConfig,
      { pathParams: { id: viewId } }
    ).pipe(
      map(view => mapRiskViewFromApiToApp(view))
    );
  }

  /**
   * Save the specified draft view as a non-draft view with updated name, description and/or
   * shared status.
   *
   * IMPORTANT Use only for Draft Risk Views created by the User. The resulting view will have
   * the same id as the draft, but will now be a non-draft "proper" view.
   *
   * @param id ID of the view to be edited
   * @param details Object containing view details to be used for save transaction
   * @param retryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public saveAs(id: number, details: CountryRiskEditConfiguration, retryAttempts?: number): Observable<CountryRiskView> {
    const modifiedDetails: CountryRiskEditConfiguration = { ...details, draft: false };
    return this.saveEdits(id, modifiedDetails, retryAttempts);
  }

  /**
   * Save a draft view by replacing the content of the risk view with the specified view ID
   * by the specified draft view.
   *
   * IMPORTANT: This method can only be used with draft views where the originating view
   * is owned by the user.
   *
   * @param viewId Unique View Id of the view to be replaced.
   * @param draftId Unique Id of the draft to be saved to the replaced view.
   * @param details Object containing view details to be used for save transaction
   * @param retryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public saveDraftToOriginalView(
    viewId: number,
    draftId: number,
    details: CountryRiskEditConfiguration,
    customRetryAttempts?: number
  ): Observable<CountryRiskView> {
    const modifiedDetails: CountryRiskEditConfiguration = { ...details, draft: false };
    return this.http.put<ApiCountryRiskConfiguration>(
      this.saveDraftResourceConfig,
      {
        body: modifiedDetails,
        pathParams: { viewId, draftId },
        retryOptions: { customRetryAttempts }
      }
    ).pipe(
      map(mapRiskViewFromApiToApp)
    );
  }

  /**
   * Share a risk view. Returns an Observable of the updated Risk View if sharing was successful.
   * Otherwise, handle HttpErrorResponse.
   *
   * IMPORTANT Use only for Risk Views created by the User.
   *
   * @param id Unique ID of risk view
   * @param retryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public shareCreatedRiskView(id: number, retryAttempts?: number): Observable<CountryRiskView> {
    const patch: CountryRiskEditConfiguration = { shared: true };
    return this.saveEdits(id, patch, retryAttempts);
  }

  /**
   * Unshare a risk view. Returns an Observable of the updated Risk View if unsharing was successful.
   * Otherwise, handle HttpErrorResponse.
   *
   * IMPORTANT Use only for Risk Views created by the User.
   *
   * @param id Unique ID of risk view
   * @param retryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  public unshareCreatedRiskView(id: number, retryAttempts?: number): Observable<CountryRiskView> {
    const patch: CountryRiskEditConfiguration = { shared: false };
    return this.saveEdits(id, patch, retryAttempts);
  }

  /**
   * Create a shared link
   * @param viewId a view id to assign the link to
   * @returns Observable<SharedLink>
   */
  public createSharedLink(viewId: number): Observable<SharedLink> {
    return this.http.post<SharedLink>(
      this.sharedLinksResourceConfig,
      { body: { view: viewId } },
    );
  }

  /**
   * Get shared links that match the filter options, paginated
   * @param options  request options
   * @param queryParams filter parameters to pass to the endpoint
   * @returns Observable<Shared<SharedLink[]>
   */
  public getSharedLinks(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    queryParams: SharedLinksQueryParams = {}
  ): Observable<PaginatedSharedLinks> {
    return this.http.get<PaginatedSharedLinks>(
      this.sharedLinksResourceConfig,
      { ...options, queryParams, },
    );
  }

  /**
   * Get all shared links that match the filter options, unpaginated
   * @param options  request options
   * @param queryParams filter parameters to pass to the endpoint
   * @returns Observable<SharedLink[]>
   */
  public getAllSharedLinks(
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
    queryParams: SharedLinksQueryParams = {}
  ): Observable<SharedLink[]>{
    let page = 1;

    return this.getSharedLinks(options, queryParams).pipe(
      expand(data => data?.links?.next
        ? this.getSharedLinks(options, { ...queryParams, page: ++page })
        : EMPTY
      ),
      reduce((acc, data) => ({ ...data, results: acc.results.concat(data.results) })),
      map(data => data.results),
    );
  }

  /**
   * Delete a specific shared link
   * @param linkId shared link id
   * @returns Observable<SharedLink>
   */
  public deleteSharedLink(linkId: string): Observable<void> {
    return this.http.delete(this.sharedLinksDetailResourceConfig, { pathParams: { linkId } });
  }

  /**
   * Bulk delete shared links
   * @param viewId view id that shared links created for
   * @param linkIds shared link ids
   */
  public deleteSharedLinks(viewId?: number, linkIds?: string[]): Observable<void> {
    const body: SharedLinksBulkDeletePayload = {};

    if (viewId) {
      body.view_id = viewId;
    } else if (linkIds) {
      body.shared_link_ids = linkIds;
    }

    return this.http.post(
      this.sharedLinksBulkDeleteResourceConfig,
      { body },
    );
  }

  /**
   * Accept a specific shared link (assign requester as recipient)
   * @param linkId shared link id
   * @returns Observable<SharedLink>
   */
  public acceptSharedLink(linkId: string): Observable<SharedLink> {
    return this.http.patch<SharedLink>(
      this.sharedLinksAcceptResourceConfig,
      { body: {}, pathParams: { linkId } },
    );
  }

  /**
   * Save edits to the specified risk view.
   *
   * IMPORTANT Use only for Risk Views created by the User.
   *
   * @param id ID of the view to be edited
   * @param edits Object containing details of edit
   * @param retryAttempts Number of retry attempts for the API call, if a server-side or client-error occurs. This overrides
   * the configured default number of retries. Use zero for no retries.
   */
  private saveEdits(id: number, edits: CountryRiskEditConfiguration, customRetryAttempts?: number): Observable<CountryRiskView> {
    return this.http.patch<ApiCountryRiskConfiguration>(
      this.configurationResourceConfig,
      {
        body: edits,
        pathParams: { id },
        retryOptions: { customRetryAttempts }
      }
    ).pipe(map(mapRiskViewFromApiToApp));
  }

  private riskViewsErrorLoggingWrapper(view: ApiCountryRiskConfiguration): RiskView {
    // GRID-339 - detect invalid views and log
    try {
      return mapRiskViewFromApiToApp(view);
    } catch (error) {
      this.loggingService.log(`Failed to map view with ID:  ${view.id}
      ${error}
      `, { level: Severity.Error });
      throw error;
    }
  }

}

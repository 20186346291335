import { FeatureToggleService } from '@grid-ui/common';
import { environment } from '@grid-ui/environment';
import { LoggingService } from '@grid-ui/logging';
import { MapsService } from '@grid-ui/maps';
import { appendGoogleTagManagerScript, appendHotjarScript } from '@grid-ui/user-analytics';
import { selectLoggedIn } from '@grid-ui/whoami';
import { Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs/operators';

export function appInitializerFactory(
  loggingService: LoggingService,
  mapsService: MapsService,
  featureToggleService: FeatureToggleService,
  store: Store,
): () => Promise<any> {
  loggingService.initialise(environment.appConfig.sentryDsn);

  if (environment.appConfig.hotjarTrackingCode) {
    appendHotjarScript(environment.appConfig.hotjarTrackingCode);
  }

  if (environment.googleTagManagerId) {
    appendGoogleTagManagerScript(environment.googleTagManagerId);
  }

  // Authenticate the maps service early once logged in
  store.select(selectLoggedIn).pipe(
    filter(loggedIn => loggedIn),
    take(1),
    switchMap(() => mapsService.authenticate())
  ).toPromise();

  return () =>
    featureToggleService.init().toPromise()
      .catch((error) => {
        console.error('An error occurred during the Angular App initialization process: ', error);
        throw error;
      });

}

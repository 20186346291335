<div *ngIf="value"
  mcWrittenContentAnchorClickListener
  gridUiCitationPopover
  [host]="citationID"
  (anchorClick)="emitWrittenContentLinkAction($event)"
  class="grid-ui-body-item-paragraph-container"
>
    <div class="item-paragraph"
      [innerHtml]="value | updateLink | safeHtml"
    ></div>
    <grid-ui-citation #citationID data="citationData"></grid-ui-citation>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export interface FeatureToggles {
  [key: string]: boolean | undefined;
  industry?: boolean;
  industryBestWorst?: boolean;
  industryGuidedTour?: boolean;
};

export const FEATURE_FLAGS_URL = '/fe-api/feature-flag/';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  protected featureToggles: FeatureToggles | undefined;
  protected featureTogglesLoaded = false;
  private readonly url: string = FEATURE_FLAGS_URL;

  constructor(private readonly http: HttpClient) {}

  public getFeatureToggles(): FeatureToggles {
    if (!this.featureTogglesLoaded || !this.featureToggles) {
      throw new Error('FeatureToggleService::getFeatureToggles() called before initialised');
    }
    return R.clone(this.featureToggles);
  }

  public init() {
    return this.http.get<FeatureToggles>(this.url).pipe(
      catchError(error => {
        // we want to swallow 403 errors at this stage to allow rest of app to load properly and handle users
        // with auth issues correctly
        if (error.status === 403 || error.status === 401) {
          return of({});
        }

        return throwError(error);
      }),
      tap((featureToggles: FeatureToggles) => {
        this.featureToggles = featureToggles;
        this.featureTogglesLoaded = true;
      })
    );
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ServiceOfferingCard } from '../../../home/models';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'mc-app-side-area',
  templateUrl: './app-side-area.component.html',
  styleUrls: ['./app-side-area.component.scss']
})
export class AppSideAreaComponent implements OnInit, OnDestroy {

  @Input() showSideNavigation = false;
  @Input() isNotificationBarOpen = false;

  public currentModule: string | null = null;
  public permittedNavItems: ServiceOfferingCard[];

  private readonly moduleUrlRe = /\/([\w-]+)\/?.*/;
  private routerSubscription: Subscription;

  constructor(
    private navService: NavService,
    private readonly router: Router
  ) {
    this.routerSubscription = this.router.events.pipe(
      filter<NavigationEnd>(event => event instanceof NavigationEnd),
    ).subscribe(evt => {
      const matches = this.moduleUrlRe.exec(evt.url);
      if (matches && matches.length > 1) {
        this.currentModule = matches[1];
      }
    });
  }

  public ngOnInit(): void {
    this.setServiceOfferings();
  }

  public ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private setServiceOfferings(): void {
    this.navService.getPermissions()
      .then(
        (serviceOfferings: ServiceOfferingCard[]) => {
          this.permittedNavItems = serviceOfferings;
        }
      );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@grid-ui/environment';
import { map, retry } from 'rxjs/operators';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  constructor(private readonly http: HttpClient) {};

  /**
   * Authenticates using GRiD session cookies or a
   * GRiDAuthorization header set by the Angular
   * development proxy.
   *
   * This endpoint will set CloudFront signed cookies
   * in the response which are used to access other
   * Maps endpoints.
   *
   * The underlying endpoint will be cached in CloudFront
   * upon successful authentication.
   *
   * @returns An observable returning `true` if authenticated, otherwise `false`.
   */
  public authenticate(): Observable<boolean> {
    return this.http.head<boolean>(`${environment.mapsBackendBaseURL}/v1/authenticate`, { observe: 'response', withCredentials: true })
      .pipe(
        retry(3),
        map(value => !!value.body)
      );
  };

  /**
   * Gets the URL for a raster.
   * @param rasterName Normally an index view slug e.g. deforestation_2023-Q1
   * @returns The absolute or relative URL depending on environment.
   */
  public urlForRaster(rasterName: string): string {
    return `${environment.mapsBackendBaseURL}/v1/rasters/subnational/${rasterName}.tif`;
  };

  /**
   * Gets the MVT-compliant XYZ URL for a vector tile set.
   * @param vectorTilesetName Normally the schema name and version e.g. grid-analyse_v0.1.2.
   * @returns The absolute or relative URL depending on environment.
   */
  public urlForVector(vectorTilesetName: string): string {
    return `${environment.mapsBackendBaseURL}/v1/vectors/${vectorTilesetName}/{z}/{x}/{y}.mvt`;
  };
}

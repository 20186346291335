import { inject, Injectable } from '@angular/core';
import { environment } from '@grid-ui/environment';
import { selectWhoAmI } from '@grid-ui/whoami';
import { Store } from '@ngrx/store';
import { Primitive } from '@ngrx/store/src/models';
import { captureEvent, captureException, init, Integrations, setUser, Severity } from '@sentry/browser';
import { LogConfig } from './models';
import { extractError } from './utils';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private readonly store = inject(Store);

  public initialise(dsn?: string): void {
    if (dsn) {
      init({
        dsn,
        release: '__mcDeployToken_sentryRelease__',
        environment: environment.environment,
        integrations: [
          new Integrations.TryCatch({
            XMLHttpRequest: false,
            // setTimeout is needed to prevent duplicate errors being logged, ideally remove this if
            // sentry fix this issue https://github.com/getsentry/sentry-javascript/issues/2744
            setTimeout: false
          })
        ],
      });

      this.store.select(selectWhoAmI).subscribe(whoAmI => {
        if (whoAmI) {
          setUser({
            id: whoAmI.user_uuid,
            email: whoAmI.email,
            ip_address: '{{auto}}',
            account: whoAmI.account_uuid,
            organization: whoAmI.organisation,
          });
        } else {
          setUser(null);
        }
      });
    }
  }

  public log(message: string, config?: LogConfig): void {
    let contexts: Record<string, Record<string, unknown>>;
    let tags: Record<string, Primitive>;

    if (config?.errorType) {
      tags = {
        gridErrorType: config.errorType,
      };
    }

    if (config?.additionalInfo) {
      contexts = config.additionalInfo;
    }

    captureEvent({
      message,
      level: Severity.fromString(config?.level ?? Severity.Info),
      contexts,
      tags,
    });
  }

  /**
   * Manually log a caught error to Sentry, usually you will not require this as we prefer errors to bubble up to the global error handler
   * however in some cases (e.g. rxjs streams / ngrx) it is necessary to catch and swallow errors to prevent breaking the application
   * where an error may not be critical and we wish to continue
   * @param error the caught error object
   */
  public logUnexpected(error: any) {
    captureException(extractError(error));
  }
}

<div class="center grid-ui-error-container" *ngIf="params?.showErrorMsg() && params?.getError(); let error">
  <grid-ui-error
    [errorCode]="error?.errorCode"
    [errorMessage]="error?.errorMessage"
    [errorPrimaryAction]="error?.errorPrimaryAction"
    [errorSecondaryAction]="error?.errorSecondaryAction"
    [showContact]="error?.showContact"
    [contactEmail]="error?.contactEmail"
    (primaryActionClick)="params?.loadData()"
    (secondaryActionClick)="reloadApp()"
  >
  </grid-ui-error>
</div>

<div class="justify-content-center align-items-center loading-container"
     *ngIf="params?.showLoadingMsg()">
      <div>
        <div class="justify-content-center">
          <i class="fas fa-spinner fa-2x fa-spin loading-icon"></i>
        </div>
        <div class="justify-content-center mt-3">
          <p class="font-italic">Loading, please wait...</p>
        </div>
      </div>
</div>

<div class="additional-subscribing" *ngIf="!params?.showLoadingMsg() && !params?.showErrorMsg()">
  <div class="icon-container" [ngSwitch]="params?.name">
    <grid-ui-icon *ngSwitchCase="'index'" class="icon line-chart-icon" name="line-chart"></grid-ui-icon>
    <grid-ui-icon *ngSwitchCase="'profile'" class="icon txt-icon" name="txt"></grid-ui-icon>
    <grid-ui-icon *ngSwitchCase="'country'" class="icon globe-icon" name="globe"></grid-ui-icon>
    <grid-ui-icon *ngSwitchCase="'industry'" class="icon globe-icon" name="industry"></grid-ui-icon>
  </div>
  <div *ngIf="showAdditionalMsg()" class="additional-msg">
    <h3>Interested in subscribing to additional {{ params?.namePlural }}?</h3>
    <div>
      <p>If you're interested in subscribing to additional {{ params?.namePlural }} please use the show restricted <br/>
        toggle above to see the {{ params?.namePlural }} available or <a href="mailto: support@maplecroft.com <support@maplecroft.com>">contact us</a> for more information
      </p>
    </div>
  </div>
  <div *ngIf="showSubscribedToAllMsg()" class="subscribed-to-all-msg">
    <h3>You are subscribed to all available risk {{ params?.namePlural }}</h3>
    <div>
      <p>If you have any questions about our risk {{ params?.name }} offering <a href="mailto: support@maplecroft.com <support@maplecroft.com>">contact us</a> for more information
      </p>
    </div>
  </div>
  <div *ngIf="showNoMatchMsg()" class="no-match-msg">
    <h3>Interested in subscribing to additional {{ params?.namePlural }}?</h3>
    <div>
      <p>If you could not find the additional risk {{ params?.name }} you are looking for, <br/>
        <a href="mailto: support@maplecroft.com <support@maplecroft.com>">contact us</a> for more information
      </p>
    </div>
  </div>
  <div *ngIf="showNoResultMsg()" class="no-match-msg">
    <h3>{{ params?.noResultsMessage }}</h3>
    <div>
      <p>If you could not find the risk {{ params?.name }} you are looking for, <br/>
        <a href="mailto: support@maplecroft.com <support@maplecroft.com>">contact us</a> for more information
      </p>
    </div>
  </div>
</div>
